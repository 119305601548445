<template>
    <div class="box">
        <div class="background"></div>
        <div class="homepage-header flex x-left">
            <Header type="2" :navList="navList" :navCurrentId="navCurrentId">
                <div slot="search">
                    <div style="width:200px;"></div>
                </div>
            </Header>
        </div>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item mh">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="flex x-left flex-wrap" v-if="albumList.length">
                        <router-link class="album-item mb20" v-for="(item,index) in albumList" :key="index" :to="{ path: '/organization/org-album-detail',query:{id:item.id,title:item.cn_title}}">
                            <img class="album-item-img" :src="item.images[0].image_url | urlFilter(250)" alt="">
                            <span class="row-2 color333 t-l fs16 mt10">{{item.cn_title}}</span>
                        </router-link>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!albumList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="albumTotal > 0">
                    <a-pagination :total="albumTotal" v-model:current="albumParams.page" :page-size="albumParams.limit"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { orgDetailList } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import storage from 'store'

export default {
    name: "index",
    components: {
        Header, Footer, 
    },
    data() {
        return {
            navList: orgDetailList(storage.get('commiittee_id')),//顶部菜单
            navCurrentId: 5,//当前页面菜单对应下标
            loading: true,
            albumParams:{
                committee_id: storage.get('commiittee_id'),
                limit: 12,
                page: 1,
            },
            albumTotal:0,
            albumList:[],
        }
    },
    created() {
        this.getAlbumList()
    },
    methods: {
        getAlbumList(){
            this.loading = true
            this.request.post('MeetingPictureList',this.albumParams).then(res=>{
                let albumList = res.data
                this.albumTotal = albumList.length
                this.albumList = albumList.slice(this.albumParams.page*12-12,this.albumParams.page*12)
                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.albumParams.page = page
            this.getAlbumList()
        },
    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.mh{
    min-height: 500px;
}
.homepage-list {
    width: 100%;
    
    .album-item{
        width: 285px;
        height: 215px;
        margin-right: 20px;
        &-img{
            width: 285px;
            height: 160px;
        }
    }
    .album-item:nth-child(4n+4){
        margin-right: 0;
    }
}
@media screen and (max-width:768px){
    .homepage-content{
        width: 100%;
        padding: 10px;
        .homepage-list{
            .album-item{
                width: 49%;
                margin-right: 2%;
                &:nth-child(4n+4),&:nth-child(2n+2){
                    margin-right: 0!important;
                }
                &-img{
                    width: 100%;
                    
                    object-fit: cover;
                }
            }
        }
    }
}
@media screen and (max-width:550px){
    .homepage-content{
        .homepage-list{
            .album-item{
                max-height: 150px;
                &-img{
                    max-height: 100px;
                }
            }
        }
    }
}
</style>